<template>
  <main>
    <menu-tab :menu="menu" :slidesPerView="2" :initIndex="type" @change="selectMenu"></menu-tab>
    <question v-show="currentMenuItem == menu[0]"></question>
    <message  v-show="currentMenuItem == menu[1]"></message>
  </main>
</template>


<script lang="ts">
  import { defineComponent, ref } from "vue";
  import MenuTab from "@/components/common/MenuTab.vue";
  import Question from "@/components/Suggest/Question.vue";
  import Message from "@/components/Suggest/Message.vue";
  import { useRoute, useRouter} from 'vue-router'
  export default defineComponent({
    name: "Suggest",
    components: {
      MenuTab,
      Question,
      Message,
    },
    setup() {
      //  切换列表菜单
      type Menu = "提交问题"  | "留言记录";
      const menu: Menu[] = ["提交问题", "留言记录"];
      const route = useRoute()
      const type = Number(route.query.type || 0);

      const currentMenuItem = ref(menu[Number(type)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        menu,
        type,
        currentMenuItem,
        selectMenu,
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 110px;
  }
  .question-class {
    padding: 15px 0;
    border-top: 6px solid #f4f4f4;
    display: flex;
    justify-content: space-around;
  }
  .question-class li {
    width: 90px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 12px;
    color: #666666;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
  }

  .question-class .active {
    color: #b9135a;
    border-color: #b9135a;
  }
  .reward {
    display: flex;
    justify-content: flex-start;
    width: 355px;
    margin: 20px auto;

    .reward-input {
      width: 20%;
      border: 1px solid #ccc;
    }
  }
  .submit {
    width: 355px;
    margin: 50px auto 0;
  }
</style>
