<template>
  <div class="after-sale">
    <ul>
      <li v-for="(item, index) in guestbookList" :key="index">
        <router-link
          :to="{
            name: 'Personal',
            query: { uid: my.id, type: 'mine' },
          }"
        >
          <img class="avatar" :src="my.userImg" alt="" />
        </router-link>
        <div class="user-info">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="user-name">
            <router-link
              :to="{
                name: 'Personal',
                query: { uid: my.id, type: 'mine' },
              }"
            >
              {{ my.nickName }}
            </router-link>
          </div>
          <div id="contentHtml" class="user-summary" v-html="item.content"></div>
          <div class="other-info">
            <div class="date">
              {{ item.createTime ? dateFormat(item.createTime) : "暂无" }}
            </div>
          </div>
          <div class="video-wapper" v-show="item.expand">
            <img
                    v-for="(itm, i) in item.imgUrls"
                    :key="i"
                    :src="itm"
                    alt="意见图片"
            />
            <video
                    v-if="item.videos && item.videos != ''"
                    controls
                    :src="item.videos"
            ></video>
          </div>

          <div class="bbs01_hui">
            <div
                    v-if="item.replyContent"
                    class="reply-wapper"
                    style="text-align: left"
            >
              <span>管理员回复：</span>
              {{ item.replyContent }}积分奖励：{{ item.rewardScore }}
              {{ dateFormat(item.replyTime) }}
            </div>

            <div v-else class="reply-wapper" style="text-align: left">
              <span>尚无回复</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <empty
            text="留言"
            :showLive="false"
            v-if="guestbookList && !guestbookList.length"
    ></empty>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import dateFormat from "@/utils/dateFormat";
  import Empty from "../common/Empty.vue";
  import qs from "qs";
  export default defineComponent({
    components: { Empty },
    name: "Message",
    setup() {
      const my = ref(null);
      const guestbookList = ref(null);

      axios
              .get("/M/Home/GuestBug", { params: { validate: true } })
              .then((res) => {
                if (res.data.success) {
                  my.value = res.data.obj.my;
                  res.data.obj.guestbookList.forEach((item: any) => {
                    item.imgUrls = item.imgUrls ? item.imgUrls.split(",") : [];
                    item.expand = true;
                  });

                  guestbookList.value = res.data.obj.guestbookList;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log('err')
              });
      function deleteMessage(id: string, index: number) {
        axios
                .post(
                        "/M/Home/DelGuestBug",
                        qs.stringify({
                          id,
                        }),
                        { params: { validate: true } }
                )
                .then((res) => {
                  if (res.data.success) {
                    (guestbookList.value as any).splice(index, 1);
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err)
                });
      }
      function switchExpand(index: number) {
        if (guestbookList.value) {
          (guestbookList.value as any)[
                  index
                  ].expand = !(guestbookList.value as any)[index].expand;
        }
      }
      return {
        my,
        guestbookList,
        dateFormat,
        switchExpand,
        deleteMessage
      };
    },
  });
</script>

<style>
  #contentHtml video{
    width: 273px!important;
  }
  #contentHtml img {
    max-height: 2.72rem;
    max-width: 2.72rem;
  }
</style>

<style lang="scss" scoped>
  .leaving-message {
    width: 9.467rem;
    margin: 0 auto;
    margin-top: 0.8rem;
  }
  .leaving-message li {
    margin-top: 0.533rem;
  }
  .leaving-message .leaving-title {
    text-decoration: none;
    color: #666;
    font-size: 0.4rem;
  }

  .leaving-type {
    margin-left: 0.267rem;
  }

  .leaving-date {
    color: #999;
    font-size: 0.4rem;
    margin: 0.267rem 0;
    margin-top: 0.133rem;
    position: relative;
  }

.view-detail {
  color: #b9135a;
  font-size: 0.32rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 0.32rem;
}

.delete {
  color: #b9135a;
  font-size: 0.32rem;
  cursor: pointer;
  position: absolute;
  right: 0.8rem;
  top: 0;
  line-height: 0.32rem;
}

  .reply-wapper {
    background: #f4f4f4;
    font-size: 15px;
    color: #999;
    padding: 0.267rem;
  }

  .video-wapper {
    height: auto;
  }

  .video-wapper img{
    max-height: 2.72rem;
    max-width: 2.72rem;
  }

  .video-wapper video {
    max-width:7.73rem;
  }

  .opened-wapper {
    padding: 0.533rem 0.267rem;
    vertical-align: middle;
    padding-left: 0;
    padding-right: 0;
    color: #666;
    font-weight: 600;
    font-size: 0.4rem;
  }
  .bbs01_hui{
    margin-top: 8px;
  }








  .after-sale{
    width: 355px;
    margin: 0 auto;

  }


  .after-sale li{
    padding: 15px 10px 20px;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    padding-left: 60px;
  }

  .after-sale .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 15px;
  }

  .user-info .title , .user-summary{
    display: block;
    text-decoration: none;
    color: #666;
    font-size: 15px;
  }
  .user-summary video{
    width: 273px !important;
  }
  #contentHtml video{
    width: 273px!important;
  }

  .user-info .user-name{
    color: #20c2d6;
    font-size: 15px;
    display: block;
    text-decoration: none;
    margin: 5px 0;
  }

  .other-info{
    overflow: hidden;
    color: #999;
    font-size: 15px;
    margin-top: 15px;
  }

  .views{
    float: right;
  }

  .views img{
    vertical-align: middle;
    position: relative;
    top: -1px;
  }

  .date{
    float: left;
  }
</style>
