<template>
  <div>
    <ul class="question-class">
      <li
        :class="currentItem == item ? 'active' : ''"
        v-for="(item, index) in secondMenu"
        :key="index"
        @click="selectSecondMenu(item, index)"
      >
        {{ item }}
      </li>
    </ul>
    <ui-form
            v-model:content="content"
            v-model:file="files"
    ></ui-form>
    <ui-button class="submit" type="submit" @handleclick="submitTopic()">保存</ui-button>
    <loading :options="loadOption" v-if="loading"></loading>
  </div>
</template>


<script lang="ts">
  import { defineComponent, ref } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import UiForm from "@/components/common/QuestionForm.vue";
  import UiButton from "@/components/UI/Button";
  import { useRoute, useRouter } from "vue-router";
  import qs from "qs";
  import Loading from "@/components/UI/Loading/src/Loading.vue";

  export default defineComponent({
    name: "Question",
    components: {
      UiForm,
      UiButton,
      Loading,
    },
    setup() {
      const router = useRouter();

      type secondMenuType =| "网站BUG建议"| "留言和反馈";
      const secondMenu: secondMenuType[] = ["网站BUG建议", "留言和反馈"];
      const currentItem = ref(secondMenu[0]);

      function selectSecondMenu(item: secondMenuType, index: number) {
        currentItem.value = item;
      }
      const content = ref("");
      const files = ref([]);

      const imageReg = /image\/(.+)/;
      const videoReg = /video\/(.+)/;
      function upload(files: any[]) {
        return new Promise((resolve, reject) => {
          const fileEmpty = files.length === 0;
          let url = "";
          let pics = "",
                  videos = "",
                  isImg = false,
                  isVideo = false;

          if (fileEmpty) {
            resolve({ pics, videos });
          }
          const file = files[0].file;
          if (imageReg.test(file.type)) {
            url = "/M/FroalaApi/UploadImage";
            isImg = true;
          }
          if (videoReg.test(file.type)) {
            url = "/M/FroalaApi/UploadVideo";
            isVideo = true;
          }

          const formData = new FormData();
          files.forEach((item) => {
            formData.append("file", item.file);
          });
          try {
            axios
                    .post(url, formData, {
                      timeout: 0,
                    })
                    .then((res) => {
                      if (res.data.success) {
                        if (isImg) {
                          pics = res.data.returnUrl;
                        }
                        if (isVideo) {
                          videos = res.data.returnUrl;
                        }
                        resolve({ pics, videos });
                      }
                    });
          } catch (e) {
            Toast({
              type: "error",
              title: Object.prototype.toString.call(e),
            });
          }
        });
      }

      const loading = ref(false);
      const loadOption = {
        text: "正在上传...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      function submitTopic() {
        if (content.value == "") {
          Toast({
            title: "请输入帖子内容！",
            type: "warning",
          });
          return false;
        }

        let kid = 1;
        switch (currentItem.value) {
          case "网站BUG建议":
            kid = 1;
            break;
          case "留言和反馈":
            kid = 2;
            break;
        }

        loading.value = true;
        upload(files.value).then((res: any) => {
          const data = qs.stringify({
            kid,
            content: content.value,
            ...res,
          });
          axios
                  .post("/M/UserServer/DoPostGuestbook?rnd=", data)
                  .then((res) => {
                    if (res.data.success) {
                      loading.value = false;
                      Toast({
                        type: "success",
                        title: res.data.msg,
                        onClose: () => {
                          router.push({
                            name: "Mine",
                          });
                        },
                      });
                    } else {
                      loading.value = false;
                      Toast({
                        type: "error",
                        title: res.data.msg,
                      });
                    }
                  })
                  .catch((err) => {
                    loading.value = false;
                    console.log("err");
                  });
        });
      }

      return {
        content,
        files,
        secondMenu,
        currentItem,
        selectSecondMenu,
        submitTopic,
        loading,
        loadOption
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 110px;
  }
  .question-class {
    padding: 15px 0;
    border-top: 6px solid #f4f4f4;
    display: flex;
    justify-content: space-around;
  }
  .question-class li {
    width: 90px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 12px;
    color: #666666;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
  }

.question-class .active {
  color: #b9135a;
  border-color: #b9135a;
}
.reward {
  display: flex;
  justify-content: flex-start;
  width: 355px;
  margin: 20px auto;

    .reward-input {
      // width: 20%;
      // border: 1px solid #ccc;
      border: none;
      color: #ffffff;
      background-color: #ffffff;
    }
  }
  .submit {
    width: 355px;
    margin: 50px auto 0;
  }
</style>
